.container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.HeaderContainer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.Header {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  position: relative;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.Logo {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.text {
  color: #6209c6;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
}
.link {
  display: contents;
}
.icon {
  fill: #6209C6;
  width: var(--dl-size-size-doubleunit);
  height: var(--dl-size-size-doubleunit);
  list-style-type: disc;
  text-decoration: none;
  list-style-image: none;
  list-style-position: outside;
}
.MobileMenu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  z-index: 101;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.Top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: space-between;
}
.Logo1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.image {
  width: 100px;
  object-fit: cover;
}
.CloseMenu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.icon02 {
  width: 24px;
  height: 24px;
}
.Mid {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.text01 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.text01:hover {
  color: var(--dl-color-turquoise-default);
}
.text02 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.text02:hover {
  color: var(--dl-color-turquoise-default);
}
.text03 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.text03:hover {
  color: var(--dl-color-turquoise-default);
}
.text04 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.text04:hover {
  color: var(--dl-color-turquoise-default);
}
.text05 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.text05:hover {
  color: var(--dl-color-turquoise-default);
}
.text06 {
  transition: 0.3s;
}
.text06:hover {
  color: var(--dl-color-turquoise-default);
}
.Bot {
  flex: 0 0 auto;
  display: flex;
  margin-top: auto;
  align-items: flex-start;
  flex-direction: column;
}
.container1 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.link1 {
  display: contents;
}
.container2 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.icon04 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.icon04:hover {
  fill: var(--dl-color-purple-default);
}
.link2 {
  display: contents;
}
.container3 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.icon06 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.icon06:hover {
  fill: var(--dl-color-pink-default);
}
.link3 {
  display: contents;
}
.container4 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.icon08 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.icon08:hover {
  fill: var(--dl-color-turquoise-default);
}
.link4 {
  display: contents;
}
.container5 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.icon10 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.icon10:hover {
  fill: var(--dl-color-orange-default);
}
.Main {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.BlurBackground {
  top: 0px;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: auto;
  filter: blur(60px);
  height: 70vh;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  background-color: #f2f2f2;
}
.Hero {
  flex: 0 0 auto;
  width: 100%;
  height: 80vh;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.container6 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.text07 {
  color: #6209c6;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.text11 {
  max-width: 400px;
}
.text12 {
  white-space: pre-wrap;
}
.image1 {
  width: 320px;
  object-fit: cover;
}
.TurquoiseCirble {
  top: 439px;
  left: auto;
  right: 430px;
  width: 170px;
  bottom: auto;
  filter: blur(60px);
  opacity: 0.6;
  position: absolute;
  object-fit: cover;
}
.PurpleCircle {
  top: -100px;
  left: auto;
  right: -26px;
  width: 400px;
  bottom: auto;
  filter: blur(60px);
  opacity: 0.4;
  position: absolute;
  object-fit: cover;
}
@media(max-width: 991px) {
  .Hero {
    height: auto;
    flex-direction: column;
  }
  .container6 {
    align-items: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .text07 {
    text-align: center;
  }
}
@media(max-width: 767px) {
  .text07 {
    font-size: 50px;
    text-align: center;
  }
}
@media(max-width: 479px) {
  .Bot {
    width: 100%;
  }
  .text11 {
    text-align: center;
  }
}
