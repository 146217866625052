.container {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.button {
  flex: 1;
  color: var(--dl-color-gray-white);
  width: 181px;
  height: 66px;
  align-self: stretch;
  text-align: center;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius6);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-purple-default);
}
.button:hover {
  background-color: var(--dl-color-purple-900);
}
.button:active {
  background-color: var(--dl-color-purple-1000);
}
.rootClassName {
  margin-top: var(--dl-space-space-tripleunit);
}
